






















































































































import { debounceProcess } from "@/helpers/debounce";
import { ResponsePagination } from "@/models/constant/interface/common.interface";
import { Messages } from "@/models/enums/messages.enum";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import moment from "moment";
import Vue from "vue";
import { salesOrderServices } from "@/services/salesorder.service";
import { DataResponseBastWithdrawl } from "@/models/interface/salesOrder.interface";

interface LoadingComponent {
  loadingFind: boolean;
  loadingInternalContractNumber: boolean;
  download: boolean;
  print: boolean;
  loadingStatus: boolean;
  loadingCustomer: boolean;
}

export default Vue.extend({
  name: "TarikUnit",
  data() {
    this.getWoNumber = debounceProcess(this.getWoNumber, 200);
    this.getInternalContractNumber = debounceProcess(
      this.getInternalContractNumber,
      200
    );
    this.getCustomerName = debounceProcess(this.getCustomerName, 200);
    return {
      form: this.$form.createForm(this, { name: "tarikUnit" }),
      page: this.page || (1 as number),
      limit: this.limit || (10 as number),
      params: {} as RequestQueryParamsModel,
      customerId: "" as string,
      icNumber: "" as string,
      totalData: 0 as number,
      loading: {
        loadingFind: false,
        loadingInternalContractNumber: false,
        download: false,
        print: false,
        loadingStatus: false,
        loadingCustomer: false,
      } as LoadingComponent,
      columnsTable: [
        {
          title: "No",
          dataIndex: "no",
          key: "no",
          width: 150,
          scopedSlots: { customRender: "no" },
        },
        {
          title: "Internal Contract Number",
          dataIndex: "icNumber",
          key: "icNumber",
          width: 150,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_customer_name"),
          dataIndex: "customerName",
          key: "customerName",
          width: 150,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: "Rent Period",
          dataIndex: "rentPeriod",
          key: "rentPeriod",
          width: 150,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: "Qty",
          dataIndex: "qty",
          key: "qty",
          width: 150,
          scopedSlots: { customRender: "isQty" },
        },
        {
          title: this.$root.$t("lbl_action").toString(),
          dataIndex: "operation",
          key: "operation",
          scopedSlots: { customRender: "operation" },
          button: ["view"],
          width: 120,
          align: "center",
        },
      ],
      formRules: {
        internalContractNumber: {
          label: "Internal Contract Number",
          name: "internalContractNumber",
          placeholder: "Select Internal Contract Number",
          decorator: [
            "internalContractNumber",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        customerName: {
          label: "lbl_customer_name",
          name: "customerName",
          placeholder: "lbl_customer_name_placeholder",
          decorator: [
            "customerName",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
      },
      dataSource: [] as DataResponseBastWithdrawl[],
      dataInternalContractNumber: [] as DataResponseBastWithdrawl[],
      dataCustomerName: [] as DataResponseBastWithdrawl[],
      woNumber: "" as string,
    };
  },
  methods: {
    responseViewTable(response) {
      this.$router.push(`/sales/tarik-unit/detail/${response.data.id}`);
    },
    handleIcNumber(value) {
      this.icNumber = value;
      this.getCustomerName("");
    },
    dynamicSearchSelect(type, valueSearch): string {
      switch (type) {
        case "icNumber":
          if (valueSearch && this.customerId)
            return `status~ENDED_OR_status~PARTIAL_ENDED_OR_status~APPROVED_AND_documentNo~*${valueSearch}*_AND_customer.secureId~${this.customerId}`;
          else if (this.customerId)
            return `status~ENDED_OR_status~PARTIAL_ENDED_OR_status~APPROVED_AND_customer.secureId~${this.customerId}`;
          else if (valueSearch)
            return `status~ENDED_OR_status~PARTIAL_ENDED_OR_status~APPROVED_AND_documentNo~*${valueSearch}*`;
          else return `status~ENDED_OR_status~PARTIAL_ENDED_OR_status~APPROVED`;
        case "customerName":
          if (valueSearch && this.icNumber)
            return `status~ENDED_OR_status~PARTIAL_ENDED_OR_status~APPROVED_AND_customer.firstName~*${valueSearch}*_OR_customer.lastName~*${valueSearch}*_AND_documentNo~${this.icNumber}`;
          else if (this.icNumber)
            return `status~ENDED_OR_status~PARTIAL_ENDED_OR_status~APPROVED_AND_documentNo~${this.icNumber}`;
          else if (valueSearch)
            return `status~ENDED_OR_status~PARTIAL_ENDED_OR_status~APPROVED_AND_customer.firstName~*${valueSearch}*_OR_customer.lastName~*${valueSearch}*`;
          else return `status~ENDED_OR_status~PARTIAL_ENDED_OR_status~APPROVED`;
        default:
          return "";
      }
    },
    getInternalContractNumber(valueSearch) {
      const params: RequestQueryParamsModel = {
        limit: 10,
        page: 0,
      };
      params.search = this.dynamicSearchSelect("icNumber", valueSearch);
      this.loading.loadingInternalContractNumber = true;
      salesOrderServices
        .getListBastWithdrawl(params)
        .then(response => {
          this.dataInternalContractNumber = response.data.map(
            (dataMap, index) => {
              return { ...dataMap, key: index, no: index + 1 };
            }
          );
        })
        .finally(() => (this.loading.loadingInternalContractNumber = false));
    },
    handleChangeCustomer(value) {
      this.form.resetFields(["internalContractNumber"]);
      this.customerId = value;
      this.getInternalContractNumber("");
    },
    getCustomerName(valueSearch) {
      const params: RequestQueryParamsModel = {
        limit: 10,
        page: 0,
      };
      params.search = this.dynamicSearchSelect("customerName", valueSearch);
      this.loading.loadingCustomer = true;
      salesOrderServices
        .getListBastWithdrawl(params)
        .then(response => {
          this.dataCustomerName = response.data
            .map((dataMap, index) => {
              return { ...dataMap, key: index, no: index + 1 };
            })
            .filter(
              (data, index, self) =>
                index ===
                self.findIndex(t => t.customerName === data.customerName)
            );
        })
        .finally(() => (this.loading.loadingCustomer = false));
    },
    responsePageSizeChange(response: ResponsePagination): void {
      this.limit = response.size;
      this.page = 1;
      this.onSubmit(false);
    },
    responseCurrentPageChange(response: ResponsePagination): void {
      this.page = response.page;
      this.onSubmit(false);
    },
    checkValue(value): string {
      if (value && !value.includes("undefined")) {
        return value;
      } else {
        return ",ALL";
      }
    },
    checkParams(res): string {
      let params = "";
      // company is mandatory
      params += this.$store.state.authStore.authData.companyName;
      params += this.checkValue(`,${res["customerMaintenanceNumber"]}`);
      params += this.checkValue(`,${res["workOrderNumber"]}`);
      params += this.checkValue(`,${res["csfNumber"]}`);
      params += this.checkValue(`,${res["customerName"]}`);
      params += this.checkValue(`,${res["unitCode"]}`);
      params += this.checkValue(
        res["dateFrom"]
          ? `,${moment(res["dateFrom"]).format("DD-MMM-yyyy")}`
          : null
      );
      params += this.checkValue(
        res["dateTo"] ? `,${moment(res["dateTo"]).format("DD-MMM-yyyy")}` : null
      );

      return params;
    },
    assignSearch(key, value, and): string {
      if (key === "customerName" && value)
        return and + `customer.secureId~${value}`;
      else if (key === "internalContractNumber" && value)
        return and + `documentNo~${value}`;
      else return "";
    },
    dynamicSearch(res, paramsSearch = ""): string {
      let search = paramsSearch;
      Object.keys(res).forEach(key => {
        if (!search) {
          search = this.assignSearch(key, res[key], "");
        } else {
          search += this.assignSearch(key, res[key], "_AND_");
        }
      });
      return search;
    },
    onSubmit(firstSearch): void {
      this.form.validateFields((err, values) => {
        if (err) return;
        const params: RequestQueryParamsModel = {
          page: this.page - 1,
          limit: this.limit,
        };
        if (firstSearch) {
          params.page = 0;
          this.page = 1;
        }
        params.search = this.dynamicSearch(values, params.search);
        if (params.search) {
          params.search += "_AND_status~ENDED";
        } else {
          params.search = "status~ENDED";
        }
        if (params.search) {
          params.search += "_OR_status~PARTIAL_ENDED";
          params.search = this.dynamicSearch(values, params.search);
        }
        if (params.search) {
          params.search += "_OR_status~APPROVED";
          params.search = this.dynamicSearch(values, params.search);
        }
        this.loading.loadingFind = true;
        salesOrderServices
          .getListBastWithdrawl(params)
          .then(response => {
            this.totalData = response.totalElements;
            this.dataSource = response.data
              .map((dataMap, index) => {
                return {
                  ...dataMap,
                  key: index,
                  no:
                    response.currentPage === 0
                      ? index + 1
                      : this.limit * (this.page - 1) + index + 1,
                };
              })
              .filter(
                (data, index, self) =>
                  index === self.findIndex(t => t.icNumber === data.icNumber)
              );
          })
          .finally(() => (this.loading.loadingFind = false));
      });
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].componentOptions.children[1].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
  },
  created() {
    this.getCustomerName("");
    this.getInternalContractNumber("");
  },
  computed: {
    formItemLayout() {
      return {
        labelCol: { span: 8 },
        wrapperCol: { span: 14 },
      };
    },
  },
});
